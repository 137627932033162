import { IHTag } from 'components/common/HTag/models';

export enum StoryTellingType {
  secondary = 'secondary',
  light = 'light',
  primary = 'primary',
  darken = 'darken',
}

export interface IStoryTelling {
  img: PageContent.TUmbracoImage;
  imageAlt: string;
  title: IHTag;
  text: string;
  expansionText?: string;
  btn: {
    btnText: string;
    btnLink: string;
    ariaLabel: string;
  };
  swap?: boolean;
  rounded?: boolean;
  variant?: StoryTellingType;
  sectionLandmark: string;
  animation?: {
    url: string;
    fallbackUrl: string;
  };
  autoPlay?: string;
}
