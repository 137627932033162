import React, { FC } from 'react';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { PurposeTypes } from '../../../@types/content/purpose';

const PartnersItem: FC<PurposeTypes.TPartner> = ({ image, imageAlt, partnerURL, ariaLabel }) => {
  const newUrl = partnerURL[0].url.replace(/&amp;/g, '&');

  return (
    <li>
      <a
        href={partnerURL[0].queryString ? newUrl + partnerURL[0].queryString : newUrl}
        aria-label={ariaLabel}
        target={partnerURL[0].target}
      >
        <span className="accessibility-hidden">{ariaLabel}</span>
        <UmbracoImage image={image} alt={imageAlt} className="dt-story-telling__img" />
      </a>
    </li>
  );
};

export default PartnersItem;
