import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Banner from 'components/common/Banner';
import Partners from 'components/Partners';
import FooterPromo from 'components/FooterPromo';
import StoryTelling from 'components/StoryTelling';
import PageBreaker from 'components/common/PageBreaker';
import PromoPanel from 'components/PromoPanel';
import NewQuiz from 'components/NewQuiz';
import { StoryTellingType } from 'components/StoryTelling/models';
import getDropDownItem from 'utils/dropDownControl';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import VideoBlock from 'components/common/VideoBlock';
import Expertise from 'components/Expertise';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { PurposeBlocks, IPurposePage, TAnchorWrapperProps, ProductBlockType } from './models.d';

import 'styles/main.scss';
import './purpose.scss';

const AnchorWrapper: FC<TAnchorWrapperProps> = ({ anchor, children }) =>
  anchor ? <div id={anchor}>{children}</div> : <>{children}</>;

const PurposePage: FC<IPurposePage> = ({
  data: {
    umbracoPurpose,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
    relatedArticles: { nodes: relatedArticles },
    brandSettings: { productsCarouselTitleRegularText, productsCarouselTitleBoldText },
  },
}) => {
  const {
    seoNoIndex,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    ogPageType,
    image,
    imageAlt,
    titleRegular,
    titleBold,
    description,
    subMenu,
    didYouKnow,
    questions,
    submenuAriaLabel,
    sectionLandmark,
    link,
    xOrigin,
  } = umbracoPurpose;

  return (
    <Layout
      subMenu={{
        links: subMenu,
        ariaLabel: submenuAriaLabel,
      }}
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <div className="dt-purpose-page">
        <Banner
          title={{ regularText: titleRegular, boldText: titleBold }}
          text={description}
          textWrapElement="div"
          img={image}
          alt={imageAlt}
          large
          sectionLandmark={sectionLandmark}
        />
        {umbracoPurpose.pageBlocks.map((block) => {
          switch (block.structure) {
            case 'Purpose Heading': {
              return (
                <div className="dt-purpose-page__purpose-heading dt-container-wrapper">
                  <div className="container-fluid">
                    <DangerouslySetInnerHtml html={block.descriptionText} />
                  </div>
                </div>
              );
            }
            case 'Purpose Text Block': {
              return (
                <DangerouslySetInnerHtml
                  html={block.descriptionText}
                  className="container dt-article-main-text"
                />
              );
            }
            case PurposeBlocks.HISTORY_BLOCK: {
              return (
                <div
                  key={block.anchorTitle}
                  className="dt-purpose-page__story-first dt-container-wrapper"
                >
                  <div id={block.anchorTitle.toLowerCase()}>
                    <StoryTelling
                      img={block.image}
                      imageAlt={block.imageAlt}
                      title={{
                        regularText: block.titleRegular,
                        boldText: block.titleBold,
                      }}
                      text={block.description}
                      btn={{
                        btnLink: extractUrlFromMultiPicker(block.buttonLink),
                        btnText: block.buttonText,
                        ariaLabel: block.ariaLabel,
                      }}
                      sectionLandmark={sectionLandmark}
                    />
                  </div>
                </div>
              );
            }
            case PurposeBlocks.PAGE_BREAKER_BLOCK: {
              return (
                <PageBreaker
                  img={block.image}
                  key={`PAGE_BREAKER_BLOCK${block.imageAlt}-${block.image.fallbackUrl}`}
                  alt={block.breakerImageAlt}
                />
              );
            }
            case PurposeBlocks.STORY_TELLING_BLOCK: {
              return (
                <AnchorWrapper
                  anchor={block.anchorTitle.toLowerCase()}
                  key={`STORY_TELLING_BLOCK-${block.image.fallbackUrl}-${block.imageAlt}`}
                >
                  <StoryTelling
                    img={block.image}
                    imageAlt={block.imageAlt}
                    animation={block.animation}
                    autoPlay={block.autoPlay}
                    title={{
                      regularText: block.titleRegular,
                      boldText: block.titleBold,
                    }}
                    text={block.descriptionText}
                    expansionText={block.expansionText}
                    btn={{
                      btnText: block.buttonText,
                      btnLink: extractUrlFromMultiPicker(block.buttonLink),
                      ariaLabel: block.ariaLabel,
                    }}
                    swap={getDropDownItem(block.blockType) === ProductBlockType.Left}
                    variant={getDropDownItem(block.backgroundType) as StoryTellingType}
                    rounded={getDropDownItem(block.imageType) === 'Rounded'}
                    sectionLandmark={sectionLandmark}
                  />
                </AnchorWrapper>
              );
            }
            case PurposeBlocks.DID_YOU_KNOW_BANNER_BLOCK: {
              return (
                <FooterPromo
                  key={`DID_YOU_KNOW_BANNER_BLOCK${block.imageAlt}-${block.didYouKnowBG.fallbackUrl}`}
                  didYouKnow={{
                    labelText: block.labelText,
                    descriptionText: block.descriptionText,
                    imageAlt: block.imageAlt,
                    didYouKnowBG: block.didYouKnowBG,
                    buttonText: block.buttonText,
                    buttonURL: block.buttonURL,
                    ariaLabel: block.ariaLabel,
                  }}
                />
              );
            }
            case PurposeBlocks.PARTNERS_BLOCK: {
              return (
                <AnchorWrapper
                  anchor={block.anchorTitle.trim().toLowerCase()}
                  key={`PARTNERS_BLOCK_${block.titleRegular}-${extractUrlFromMultiPicker(
                    block.buttonUrl
                  )}`}
                >
                  <Partners
                    title={{
                      regularText: block.titleRegular,
                      boldText: block.titleBold,
                    }}
                    text={block.description}
                    partners={block.partnersList}
                    btn={{
                      btnLink: extractUrlFromMultiPicker(block.buttonUrl),
                      btnText: block.buttonText,
                      ariaLabel: block.ariaLabel,
                    }}
                    sectionLandmark={sectionLandmark}
                  />
                </AnchorWrapper>
              );
            }
            case PurposeBlocks.ASSISTANT_BLOCK: {
              return (
                <NewQuiz
                  xOrigin={xOrigin}
                  salsifyLang={siteSettings.lang}
                  questions={questions}
                  buttons={{
                    next: block.nextButtonText,
                    back: block.prevButtonText,
                    repeat: block.againButtonText,
                  }}
                  title={{
                    regularText: block.titleRegularText,
                    boldText: block.titleBoldText,
                  }}
                  searchResultsLabel={block.searchResultsPage}
                  loadingLabel={
                    block.resultsLoadingLabel ?? 'Sending request for finding products....'
                  }
                  noResultsLabel={block.noResultsFoundLabel ?? 'No results found'}
                />
              );
            }
            case PurposeBlocks.PROMO_PANEL_BLOCK: {
              return (
                <PromoPanel
                  promoProduct={{
                    productImage: block.productImage,
                    productName: block.productName,
                  }}
                  buttonText={block.buttonText}
                  buttonURL={block.buttonURL}
                  ariaLabel={block.ariaLabel}
                  descriptionText={block.descriptionText}
                  titleText={block.titleText}
                  imageAlt={block.imageAlt}
                  boldTitle={block.boldTitle}
                  labelText={block.labelText}
                  panelType={block.panelType}
                  panelImage={block.panelImage}
                  panelVideo={block.panelVideo}
                  sectionLandmark={block.sectionLandmark}
                  disclaimer={block.disclaimer}
                  productImage={block.productImage}
                  customImageToggle={block.customImageToggle}
                />
              );
            }
            case 'Video Component': {
              return (
                <div className="container-fluid">
                  <VideoBlock imageAlt="video" image={block.image} videoUrl={block.videoUrl} />
                </div>
              );
            }
            default:
              return null;
          }
        })}
      </div>
      {relatedArticles && relatedArticles.length > 0 ? (
        <Expertise
          title={{
            regularText: productsCarouselTitleRegularText,
            boldText: productsCarouselTitleBoldText,
          }}
          articles={relatedArticles}
          sectionLandmark={`${productsCarouselTitleRegularText} ${productsCarouselTitleBoldText}`}
        />
      ) : null}

      <DidYouKnowLoadableWrapper didYouKnow={didYouKnow} mask />
    </Layout>
  );
};

export const query = graphql`
  query PurposePageQuery($link: String!, $relatedArticlesLinks: [String], $lang: String) {
    relatedArticles: allUmbracoArticle(
      filter: { link: { in: $relatedArticlesLinks, nin: [$link] } }
    ) {
      nodes {
        title
        intro
        link
        label
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
      }
    }
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    brandSettings(lang: { eq: $lang }) {
      productsCarouselTitleRegularText
      productsCarouselTitleBoldText
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    umbracoPurpose(lang: { eq: $lang }, link: { eq: $link }) {
      id
      imageAlt
      seoNoIndex
      seoMetaKeywords
      seoMetaDescription
      seoMetaTitle
      seoCanonicalUrl
      relatedArticlesLinks
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      subMenu {
        name
        linkToSection
      }
      submenuAriaLabel
      sectionLandmark
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      pageBlocks {
        anchorTitle
        backgroundType
        blockType
        buttonText
        ariaLabel
        description
        descriptionText
        expansionText
        imageType
        labelText
        imageAlt
        breakerImageAlt
        name
        showInMenu
        structure
        titleBold
        titleRegular
        sectionLandmark
        heading
        videoUrl
        animation {
          url
          fallbackUrl
        }
        autoPlay
        partnersList {
          imageAlt
          partnerURL {
            icon
            name
            url
            target
            queryString
          }
          image {
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          ariaLabel
        }
        productImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        didYouKnowBG {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        buttonUrl {
          icon
          name
          url
        }
        buttonURL {
          icon
          name
          url
        }
        buttonLink {
          icon
          name
          url
        }
        buttonURL {
          icon
          name
          url
        }
        titleText
        boldTitle
        panelType
        panelImage {
          fallbackUrl
          fluid {
            base64
            srcSet
          }
        }
        panelVideo
        disclaimer
        productImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        customImageToggle
        nextButtonText
        prevButtonText
        titleRegularText
        titleBoldText
        againButtonText
        categoryQuestionLabel
        tagQuestionLabel
        searchResultsPage
        noResultsFoundLabel
        resultsLoadingLabel
      }
      link
      titleRegular
      titleBold
      title
      description
      xOrigin
      questions {
        id
        key
        label
        type
        options {
          id
          label {
            text
            image {
              fallbackUrl
              fluid {
                base64
                srcSet
              }
            }
          }
          value
          link
          tags {
            id
            label {
              text
              title
            }
            value
          }
        }
      }
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
    }
  }
`;

export default PurposePage;
