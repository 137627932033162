import React, { FC } from 'react';
import classNames from 'classnames';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { IPageBreaker } from './models';
import './PageBreaker.scss';

const PageBreaker: FC<IPageBreaker> = ({ img, alt, shadow }) => {
  const classes = classNames('dt-page-breaker', {
    'dt-page-breaker--shadow': shadow,
  });

  if (!img || !img.fluid) {
    return null;
  }

  return (
    <div className={classes}>
      <UmbracoImage image={img} alt={alt} className="dt-page-breaker__img" />
    </div>
  );
};

export default PageBreaker;
