import React, { FC } from 'react';

import Media from 'components/common/MediaInfoBox/Media';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { IVideoBlock } from './model';
import './VideoBlock.scss';

const VideoBlock: FC<IVideoBlock> = ({ videoUrl, image, imageAlt }) => (
  <section className="dt-container-wrapper dt-media-video-block">
    <div className="dt-media-video-block__holder">
      <Media video={videoUrl}>
        <UmbracoImage image={image} alt={imageAlt} />
      </Media>
    </div>
  </section>
);

export default VideoBlock;
