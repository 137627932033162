export enum PurposeBlocks {
  HISTORY_BLOCK = 'History',
  PAGE_BREAKER_BLOCK = 'Page Breaker',
  STORY_TELLING_BLOCK = 'Story Telling',
  DID_YOU_KNOW_BANNER_BLOCK = 'Did you know banner',
  PARTNERS_BLOCK = 'Our Partners',
  PROMO_PANEL_BLOCK = 'Promo Panel',
  TOP_HEADER_BLOCK = 'Top header',
  ASSISTANT_BLOCK = 'Assistant',
}

export enum ProductBlockType {
  Left = 'Left',
  Right = 'Right',
}

export interface IPurposePage {
  data: IPurposePageData;
}

interface IPurposePageData extends PageContent.IDefaultLayoutProps {
  umbracoPurpose: PurposeTypes.IPurpose;
  relatedArticles: {
    nodes: ArticleTypes.IArticle[];
  };
  brandSettings: ArticleTypes.TArticleExpertiseText;
}

export type TAnchorWrapperProps = {
  anchor?: string;
};
