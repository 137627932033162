import React, { FC, useState } from 'react';
import loadable from '@loadable/component';
import { AccordionCollapse, AccordionToggle, Accordion } from 'react-bootstrap';

import { Link } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import HTag from 'components/common/HTag';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { IStoryTelling, StoryTellingType } from './models';

import './StoryTelling.scss';

const StoryTelling: FC<IStoryTelling> = ({
  img,
  imageAlt,
  title: { regularText, boldText },
  text,
  expansionText,
  btn,
  swap,
  variant,
  rounded,
  sectionLandmark,
  animation,
  autoPlay,
}) => {
  const classes = classNames('dt-story-telling dt-container-wrapper', {
    'dt-story-telling--swap': swap,
    'dt-story-telling--rounded': rounded,
    [`dt-story-telling--${variant}`]: variant,
  });

  const [isCollapsed, setCollapsed] = useState(true);

  const handleToggleClick = () => {
    setCollapsed(!isCollapsed);
  };

  const isStoryTellingLight = variant === StoryTellingType.light;
  const btnItemClasses = classNames('btn', {
    'btn-outline-primary': isStoryTellingLight,
    'btn-light': !isStoryTellingLight,
  });

  const btnItem = (
    <Link className={btnItemClasses} to={btn.btnLink} aria-label={btn.ariaLabel}>
      {btn.btnText}
    </Link>
  );

  const LazyAnimation = animation
    ? loadable(() => import('components/common/LazyAnimation'))
    : null;

  return (
    <section className={classes} aria-label={sectionLandmark}>
      <Container className="dt-story-telling__container" fluid>
        <div className="dt-story-telling__img-holder">
          {animation ? (
            <LazyAnimation animation={animation} autoPlay={autoPlay} />
          ) : (
            <UmbracoImage image={img} alt={imageAlt} className="dt-story-telling__img" />
          )}
        </div>

        <div className="dt-story-telling__content">
          <HTag underline size={2} regularText={regularText} boldText={boldText} />
          <DangerouslySetInnerHtml html={text} />
          {btn.btnText && btnItem}
          {expansionText ? (
            <div>
              <Accordion>
                <AccordionCollapse eventKey="0">
                  <DangerouslySetInnerHtml html={expansionText} />
                </AccordionCollapse>
                <AccordionToggle
                  className="btn btn-primary dt-story-telling__expand-text"
                  onClick={handleToggleClick}
                  eventKey="0"
                >
                  {isCollapsed ? 'Read more' : 'Read less'}
                </AccordionToggle>
              </Accordion>
            </div>
          ) : null}
        </div>
      </Container>
    </section>
  );
};

export default StoryTelling;
