import { Link } from 'gatsby';
import HTag from 'components/common/HTag';
import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import PartnersItem from './PartnersItem';
import { IPartners } from './models';
import './Partners.scss';

const Partners: FC<IPartners> = ({
  title: { regularText, boldText },
  text,
  btn,
  partners,
  sectionLandmark,
}) => {
  const partnerItems = partners.map((item) => <PartnersItem key={item.imageAlt} {...item} />);

  return (
    <section className="dt-partners" aria-label={sectionLandmark}>
      <Container fluid>
        <HTag underline size={3} regularText={regularText} boldText={boldText} />
        <DangerouslySetInnerHtml html={text} />

        <div className="text-center">
          <Link className="btn btn-outline-primary" to={btn.btnLink} aria-label={btn.ariaLabel}>
            {btn.btnText}
          </Link>
        </div>

        <ul className="dt-partners__list d-none d-md-flex">{partnerItems}</ul>
      </Container>
    </section>
  );
};

export default Partners;
